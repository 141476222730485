
import Vue from 'vue';
import Spinner from "@/components/Spinner.vue";
import {mapActions, mapGetters} from "vuex";
import LoadingOverlay from "@/layouts/components/LoadingOverlay.vue";
import VueRecaptcha from 'vue-recaptcha';

export default Vue.extend({
  name: "Signup",
  components: {LoadingOverlay, Spinner, 'vue-recaptcha': VueRecaptcha},
  computed: {
    ...mapGetters('accounts', ['isLoading'])
  },

  data() {
    return {
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userPhoneNumber: '',
      userPassword1: '',
      userPassword2: '',
      userRole: '',
      userDepartment: '',
      userCompanyFunction: '',

      hasAccessCode: true,
      accessCode: this.$route.params.access_code,
      siteKey: '',
      showRecaptcha: false,

      functionOptions: [
        'Compliance',
        'Executive Management',
        'Human Resources',
        'Investment Management',
        'Legal',
        'Sales / Business Development',
        'Risk',
        'Strategy',
        'Technology',
        'Other'
      ],
      form: {
        robot: false
      }
    }
  },
  mounted() {
    if (process.env.VUE_APP_API_ENVIRONMENT == "PRODUCTION" ||
        process.env.VUE_APP_API_ENVIRONMENT == "STAGING") {
      this.siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      this.showRecaptcha = true;
    } else {
      this.siteKey = "6LdNkx0fAAAAAO8XaIgV5mKox5Jp3mgq1Z-g_-9g"
      this.showRecaptcha = true;
    }
  },
  created(){
    if (process.env.VUE_APP_API_ENVIRONMENT == "PRODUCTION" ||
        process.env.VUE_APP_API_ENVIRONMENT == "STAGING") {
      this.siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
      this.showRecaptcha = true;
    } else {
      this.siteKey = "6LdNkx0fAAAAAO8XaIgV5mKox5Jp3mgq1Z-g_-9g"
      this.showRecaptcha = true;
    }
  },
  methods: {
    ...mapActions('accounts', ['create']),

    validateRecaptcha: function () {
      const recaptcha = this.$refs.recaptcha as VueRecaptcha
      recaptcha.execute()
    },
    onVerify: function (response:any) {
      if (response){
        this.form.robot = true;
        console.log("here")
        this.handleSignup()
        this.resetRecaptcha()
      }
    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha() {
      const recaptcha = this.$refs.recaptcha as VueRecaptcha
      recaptcha.reset() // Direct call reset method
    },

    handleSignup() {

      if (this.form.robot) {
        const newUser: CreateUser = {
          firstName: this.userFirstName,
          lastName: this.userLastName,
          email: this.userEmail,
          phone: this.userPhoneNumber,
          password1: this.userPassword1,
          password2: this.userPassword2,
          role: this.userRole,
          department: this.userDepartment,
          func: this.userCompanyFunction
        }

        if (newUser.password1 !== newUser.password2) {
          this.$store.dispatch('notifications/pushNotification', {
            message: "Your passwords do not match!",
            type: 'danger',
            autoHide: false,
            redirectTo: ''
          });
          return;
        }

        let userAccessCode = this.accessCode;


        newUser.accessCode = userAccessCode;


        this.create(newUser);
      } else {
        console.log("BOT BOT BOT")
      }
    }
  }
});
